
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  name: 'MGoogleDriveUrl',
  props: {
    fetchProgram: {
      required: false,
      type: [Object, Function],
      default: () => {}
    },
    adkType: {
      required: false,
      type: String,
      default: ''
    },
    userType: {
      required: true,
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(_props, _ctx) {
    const programDocument: any = computed({
      get: () => _props.value,
      set: newVal => {
        _ctx.emit('input', newVal);
      }
    });

    const adkIndex = computed(() => {
      const indx = programDocument.value?.data.adks?.findIndex(adk => {
        if (adk) {
          return adk.name === 'Drive Links';
        }
        return false;
      });
      return indx;
    });

    const fieldIndex = computed(() => {
      const fieldIndex = programDocument.value?.data?.adks?.[adkIndex.value]?.fields?.findIndex(
        field => {
          console.log('Here');
          if (field.name) {
            return field.name === 'm-google-drive-url';
          }
          return false;
        }
      );
      return fieldIndex;
    });

    const fieldValue = computed({
      get: () => programDocument.value?.data?.adks?.[adkIndex.value]?.fields[fieldIndex.value],
      set: newVal => {
        if (fieldValue.value)
          programDocument.value.data.adks[adkIndex.value].fields[fieldIndex.value] = newVal;
      }
    });

    const participantData = computed({
      get: () => {
        if (!fieldValue.value.value) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          fieldValue.value.value = {
            textFields: [''],
            loaded: ['']
          };
        }
        return fieldValue.value.value;
      },
      set: newVal => {
        fieldValue.value.value = newVal;
      }
    });

    const organizerData = computed({
      get: () => fieldValue.value.setup,
      set: newVal => {
        fieldValue.value.setup = newVal;
      }
    });
    const textFields = computed({
      get: () => {
        if (!participantData.value.textFields) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          participantData.value.textFields = [''];
        }
        return participantData.value.textFields;
      },
      set: newVal => {
        participantData.value.textFields = newVal;
      }
    });
    const loaded = computed({
      get: () => {
        if (!participantData.value.loaded) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          participantData.value.loaded = [''];
        }
        return participantData.value.loaded;
      },
      set: newVal => {
        participantData.value.loaded = newVal;
      }
    });
    const organizerAction = {};
    const participantActions = {};
    return {
      fieldValue,
      loaded,
      textFields,
      participantActions,
      organizerAction
    };
  },
  methods: {
    loadURL(url, index) {
      const urlParts = url.split('/');
      const id = urlParts[urlParts.length - 2];
      this.textFields[index] = `https://drive.google.com/file/d/${id}/preview`;
      console.log(this.textFields[index]);
    },
    removeTextField(index) {
      this.textFields.splice(index, 1);
    }
  }
});
