import { ActivitySchema } from '../types';
import activity from './m-google-drive-url.vue';

export default activity; // re-export your activity

export const componentRegistrationData = {
  name: 'Weekly Videos', // name of your activity
  icon: 'mdi-video', // mdi icon of choice
  description: 'Weekly Google Drive videos for students to watch.',
  status: true,
  setupRequired: true,
  styling: { borderColor: '#3eb489 ' },
  fields: [
    {
      roles: ['employer', 'participant'],
      name: 'm-google-drive-url',
      type: 'molecule',
      position: 0,
      preset: {},
      setup: {},
      value: {
        // this is your participant data, what will be filled in by students
        textFields: [''],
        loaded: ['']
      }
    },
    {
      roles: ['employer', 'participant'],
      name: 'm-save-adk-only',
      type: 'molecule',
      position: 1,
      preset: {},
      setup: {},
      value: {}
    }
  ]
};
